import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';

angular
    .module('backOffice')
    .controller('PasswordParametersController', passwordParametersController);

passwordParametersController.$inject = [
    '$scope',
    '$state',
    'loginParametersService',
    'modalService',
    'toaster',
    'CompanyUserAdminSettingsService',
    'CompanyUserAccountSettingsService',
    'companyUserChallengeSettingsService',
    'accountMaskTypes',
    'entitlementsService',
];

function passwordParametersController(
    $scope,
    $state,
    loginParametersService,
    modalService,
    toaster,
    CompanyUserAdminSettingsService,
    CompanyUserAccountSettingsService,
    companyUserChallengeSettingsService,
    accountMaskTypes,
    entitlementsService
) {
    $scope.selectedTab = 'adminSettings';
    $scope.selectTab = selectTab;
    $scope.allForms = [];
    $scope.backOfficeApproveBackOffice = backOfficeApproveBackOffice;
    $scope.cancel = cancel;
    $scope.cancelAccountSettings = cancelAccountSettings;
    $scope.cancelAdminSettings = cancelAdminSettings;
    $scope.cancelChallengeSettings = cancelChallengeSettings;
    $scope.changeAccountMaskingOverride = changeAccountMaskingOverride;
    $scope.changeLockedOutMessage = changeLockedOutMessage;
    $scope.changeOobPinSettings = changeOobPinSettings;
    $scope.changeToDefaults = changeToDefaults;
    $scope.changeUseAccountNicknames = changeUseAccountNicknames;
    $scope.channelApproveBackOffice = channelApproveBackOffice;
    $scope.channelApproveChannel = channelApproveChannel;
    $scope.isChallengeSettingsSaveDisabled = isChallengeSettingsSaveDisabled;
    $scope.isNotValid = isNotValid;
    $scope.save = save;
    $scope.saveAccountSettings = saveAccountSettings;
    $scope.saveAdminSettings = saveAdminSettings;
    $scope.saveCompanyUserChallengeSettings = saveCompanyUserChallengeSettings;
    $scope.sendEmailWhileLocked = sendEmailWhileLocked;
    $scope.setAccountSettingsForm = setAccountSettingsForm;
    $scope.setAdminForm = setAdminForm;
    $scope.setCompanyUserChallengeSettingsForm = setCompanyUserChallengeSettingsForm;
    $scope.setLoginSettingsForm = setLoginSettingsForm;
    $scope.shouldRequiredChange = shouldRequiredChange;
    $scope.togglePasswordExpire = togglePasswordExpire;
    $scope.toggleSecureToken = toggleSecureToken;
    $scope.accountMaskTypes = accountMaskTypes;
    $scope.invalidWhenAlphabetRequired = invalidWhenAlphabetRequired;
    $scope.revertDisabled = revertDisabled;
    $scope.allowedEntitlements = allowedEntitlements;
    $scope.isFiLevel = isFiLevel;
    $scope.isLoginSaveDisabled = isLoginSaveDisabled;
    // TODO / FEATURE: Remove the following line when the new OOB feature is no longer dark
    $scope.isOobPinUsed = isOobPinUsed;
    $scope.isUisEnabled = false;
    $scope.displayLegacyPasswordSettings = displayLegacyPasswordSettings;

    function selectTab(tabName) {
        let showWarning = false;
        angular.forEach($scope.allForms, form => {
            if (form.$dirty) {
                showWarning = true;
            }
        });
        let modalOptions;
        let modalInstance;

        if (tabName === $scope.selectedTab) {
        } else if (showWarning) {
            modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    $scope.cancelAdminSettings();
                    $scope.cancelAccountSettings();
                    $scope.cancelChallengeSettings();
                    $scope.cancel();
                    modalInstance.close(result);

                    $scope.selectedTab = tabName;
                },
            };
            modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $scope.selectedTab = tabName;
        }
    }

    function saveAdminSettings(adminSettings) {
        CompanyUserAdminSettingsService.updateCompanyAdminSettings(adminSettings).then(response => {
            $scope.adminSettings = response;
            toaster.save('Admin Settings');
            $scope.copyAdminSettings = angular.copy($scope.adminSettings);
            $scope.adminForm.$setPristine();
        });
    }

    function saveAccountSettings(accountSettings) {
        CompanyUserAccountSettingsService.updateCompanyAccountSettings(accountSettings).then(
            response => {
                $scope.accountSettings = response;
                toaster.save('Account Settings');
                $scope.copyAccountSettings = angular.copy($scope.accountSettings);
                $scope.accountSettingsForm.$setPristine();
            }
        );
    }

    function saveCompanyUserChallengeSettings() {
        companyUserChallengeSettingsService
            .updateUserChallengeSettings($scope.companyUserChallengeSettings)
            .then(response => {
                $scope.companyUserChallengeSettings = response;
                toaster.save('Authentication Settings');
                $scope.copyCompanyUserChallengeSettings = angular.copy(
                    $scope.companyUserChallengeSettings
                );
                $scope.challengeSettingsForm.$setPristine();
            });
    }

    function changeToDefaults() {
        const modalOptions = {
            bodyText:
                'Reverting to Default settings will change all parameters on this page to the system defaults. Please select Yes to confirm this change or No to keep the current settings.',
            submit(result) {
                revertToDefaults();
                $modalInstance.close(result);
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function togglePasswordExpire() {
        $scope.loginParameters.willPasswordExpire = !$scope.loginParameters.willPasswordExpire;
    }

    function changeUseAccountNicknames() {
        $scope.companyUserAccountSettings.useAccountNicknames =
            !$scope.companyUserAccountSettings.useAccountNicknames;
    }

    function changeAccountMaskingOverride() {
        $scope.companyUserAccountSettings.allowUserToOverrideAccountMasking =
            !$scope.companyUserAccountSettings.allowUserToOverrideAccountMasking;
    }

    function changeLockedOutMessage() {
        $scope.loginParameters.allowLockedOutMessage =
            !$scope.loginParameters.allowLockedOutMessage;
    }

    function channelApproveChannel() {
        $scope.adminSettings.channelApproveChannel = !$scope.adminSettings.channelApproveChannel;
    }

    function backOfficeApproveBackOffice() {
        $scope.adminSettings.backOfficeApproveBackOffice =
            !$scope.adminSettings.backOfficeApproveBackOffice;
    }

    function changeOobPinSettings() {
        $scope.companyUserChallengeSettings.isOobAuthenticationPinRequired =
            !$scope.companyUserChallengeSettings.isOobAuthenticationPinRequired;
    }

    function toggleSecureToken() {
        if (
            $scope.companyUserChallengeSettings.isTokenEnabledForChallenges &&
            $scope.copyCompanyUserChallengeSettings.isTokenEnabledForChallenges
        ) {
            const modalOptions = {
                actionButtonText: 'Disable',
                closeButtonText: 'Cancel',
                secondaryButtonClass: 'btn-link',
                headerText: 'Disable Secure Token',
                bodyText:
                    'Authentication requirements will be removed for all companies currently using secure tokens. Are you sure you want to disable secure token authentication?',
                submit(result) {
                    toggleTokenEnabledForChallenges();
                    $modalInstance.close(result);
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            toggleTokenEnabledForChallenges();
        }
    }

    function toggleTokenEnabledForChallenges() {
        $scope.companyUserChallengeSettings.isTokenEnabledForChallenges =
            !$scope.companyUserChallengeSettings.isTokenEnabledForChallenges;
    }

    function channelApproveBackOffice() {
        $scope.adminSettings.channelApproveBackOffice =
            !$scope.adminSettings.channelApproveBackOffice;
    }

    function isChallengeSettingsSaveDisabled() {
        return !$scope.challengeSettingsForm.$dirty || invalidChallengeSettingsSelection();
    }

    function invalidChallengeSettingsSelection() {
        return (
            !$scope.companyUserChallengeSettings ||
            $scope.numberOfAllowedFailedAttempts.reduce((acc, val) => {
                if (val === $scope.companyUserChallengeSettings.numberOfOobAttemptsAllowed) {
                    acc--;
                }
                if (
                    val ===
                    $scope.companyUserChallengeSettings.numberOfTokenChallengePointAttemptsAllowed
                ) {
                    acc--;
                }
                return acc;
            }, 2) > 0
        );
    }

    function cancel() {
        loadParameters();
        $scope.loginSettingsForm.$setPristine();
    }

    function sendEmailWhileLocked() {
        $scope.loginParameters.sendLockedEmail = !$scope.loginParameters.sendLockedEmail;
    }

    function setAdminForm(form) {
        $scope.adminForm = form;
        $scope.allForms.push(form);
    }

    function setLoginSettingsForm(form) {
        $scope.loginSettingsForm = form;
        $scope.allForms.push(form);
    }

    function setAccountSettingsForm(form) {
        $scope.accountSettingsForm = form;
        $scope.allForms.push(form);
    }

    function setCompanyUserChallengeSettingsForm(form) {
        $scope.challengeSettingsForm = form;
        $scope.allForms.push(form);
    }

    function cancelAdminSettings() {
        $scope.adminSettings = angular.copy($scope.copyAdminSettings);
        $scope.adminForm.$setPristine();
    }

    function cancelAccountSettings() {
        $scope.companyUserAccountSettings = angular.copy($scope.copyCompanyUserAccountSettings);
        $scope.accountSettingsForm.$setPristine();
    }

    function cancelChallengeSettings() {
        $scope.companyUserChallengeSettings = angular.copy($scope.copyCompanyUserChallengeSettings);
        $scope.challengeSettingsForm.$setPristine();
    }

    function revertToDefaults() {
        loginParametersService
            .revertToDefaults($scope.loginParameters.id, $scope.loginParameters.isCompanyParameter)
            .then(response => {
                $scope.loginParameters = response;
                toaster.save('Revert To Defaults');
            });
    }

    function save() {
        loginParametersService.updateLoginParameters($scope.loginParameters).then(response => {
            $scope.loginParameters = response;
            toaster.save('Parameters');
        });
    }

    function loadLength() {
        $scope.lengthList = [
            4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        ];
        $scope.pwdLengthList = [
            8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        ];
    }

    function loadAccountSettings() {
        CompanyUserAccountSettingsService.getAccountSettings().then(response => {
            $scope.companyUserAccountSettings = response;
            $scope.copyCompanyUserAccountSettings = angular.copy($scope.companyUserAccountSettings);
        });
    }

    function loadChallengeSettings() {
        companyUserChallengeSettingsService.getCompanyUserChallengeSettings().then(response => {
            $scope.companyUserChallengeSettings = response;
            $scope.copyCompanyUserChallengeSettings = angular.copy(
                $scope.companyUserChallengeSettings
            );
        });
    }

    function loadCaseRequired() {
        $scope.caseRequiredList = [0, 1, 2, 3];
    }

    function loadNumOfDays() {
        $scope.numDaysList = [30, 45, 60, 90, 120, 180, 365];
    }

    function loadCompanyAdminSettings() {
        CompanyUserAdminSettingsService.getCompanyAdminSettings().then(response => {
            $scope.adminSettings = response;
            $scope.copyAdminSettings = angular.copy($scope.adminSettings);
        });
    }

    function loadWarnNumOfDays() {
        $scope.warnNumDaysList = [0, 5, 10, 15, 30];
    }

    function loadNumOfTimes() {
        $scope.numOfTimesList = [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
            24,
        ];
        $scope.coNumOfTimesList = [
            5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
        ];
    }

    function loadNumberOfInvalidAttemps() {
        $scope.numOfInvalidAttempsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        $scope.coNumOfInvalidAttempsList = [1, 2, 3];
    }

    function loadSessionTimeOutControl() {
        $scope.sessionTimeOutList = [10, 15, 20, 30];
    }

    function loadNumberOfAllowedFailedAttempts() {
        $scope.numberOfAllowedFailedAttempts = [1, 2, 3];
    }

    function loadDropDownValues() {
        loadLength();
        loadCaseRequired();
        loadNumOfDays();
        loadWarnNumOfDays();
        loadNumOfTimes();
        loadNumberOfInvalidAttemps();
        loadSessionTimeOutControl();
        loadNumberOfAllowedFailedAttempts();
    }

    function shouldRequiredChange(source, target) {
        if (source === false) {
            $scope.loginParameters[target] = false;
        }
    }

    function loadParameters() {
        loginParametersService.getLoginParameters($scope.type).then(response => {
            $scope.loginParameters = response;
            if ($state.current.name === 'fipp') {
                $scope.loginParameters.isCompanyParameter = false;
            } else {
                $scope.loginParameters.isCompanyParameter = true;
            }
        });
    }

    function invalidWhenAlphabetRequired() {
        return (
            $scope.loginParameters &&
            $scope.loginParameters.alphabetsInPasswordAreRequired === true &&
            $scope.loginParameters.numberOfUppercaseRequiredInPassword === 0 &&
            $scope.loginParameters.numberOfLowercaseRequiredInPassword === 0
        );
    }

    function isNotValid() {
        if ($scope.loginParameters === null || $scope.loginParameters === undefined) {
            return false;
        }

        if (
            $scope.loginParameters.loginIdMinLength === null ||
            $scope.loginParameters.loginIdMinLength === undefined ||
            $scope.loginParameters.loginIdMaxLength === null ||
            $scope.loginParameters.loginIdMaxLength === undefined ||
            $scope.loginParameters.loginIdMinLength > $scope.loginParameters.loginIdMaxLength
        ) {
            return true;
        }
        if (
            $scope.loginParameters.passwordMinLength === null ||
            $scope.loginParameters.passwordMinLength === undefined ||
            $scope.loginParameters.passwordMaxLength === null ||
            $scope.loginParameters.passwordMaxLength === undefined ||
            $scope.loginParameters.passwordMinLength > $scope.loginParameters.passwordMaxLength
        ) {
            return true;
        }
        if (
            $scope.loginParameters.allowAlphabetsInLoginId !== true &&
            $scope.loginParameters.allowNumbersInLoginId !== true &&
            $scope.loginParameters.allowSpecialCharactersInLoginId !== true
        ) {
            return true;
        }
        if (
            $scope.loginParameters.allowAlphabetsInPassword !== true &&
            $scope.loginParameters.allowNumbersInPassword !== true &&
            $scope.loginParameters.allowSpecialCharactersInPassword !== true
        ) {
            return true;
        }
        if (invalidWhenAlphabetRequired()) {
            return true;
        }
        if (
            $scope.loginParameters.isCompanyParameter &&
            (!$scope.loginParameters.redirectUrl ||
                $scope.loginParameters.redirectUrl.trim().length === 0)
        ) {
            return true;
        }

        return false;
    }

    function revertDisabled() {
        return (
            entitlementsService.hasEntitlement('Switch FI') ||
            !entitlementsService.hasAnyEntitlement(
                'Manage Company User Settings',
                'Manage FI User Settings'
            )
        );
    }

    function allowedEntitlements() {
        if (isFiLevel()) {
            return 'Manage FI User Settings';
        }
        return 'Manage Company User Settings';
    }

    function displayLegacyPasswordSettings() {
        return !$scope.isUisEnabled || isFiLevel();
    }

    function isFiLevel() {
        if ($state.current.name === 'fipp') {
            return true;
        }

        if ($state.current.name === 'cpp') {
            return false;
        }

        throw new Error(
            `Expected $state.name to be either "fipp" (FI) or "cpp" (company), but got "${$state.name}".`
        );
    }

    function isLoginSaveDisabled() {
        return (
            isNotValid() || $scope.loginSettingsForm.$invalid || !$scope.loginSettingsForm.$dirty
        );
    }

    // TODO / FEATURE: remove this when feature is no longer dark.
    function isOobPinUsed() {
        const newOobFeatureFlagOn = entitlementsService.hasEntitlement(
            'Feature.Authentication.EnsOobValidatePerFi'
        );
        return !newOobFeatureFlagOn;
    }

    (async function () {
        // init
        if ($state.current.name === 'fipp') {
            $scope.type = 'fi';
            $scope.headerLabel = 'FI User Settings';
        } else if ($state.current.name === 'cpp') {
            $scope.type = 'company';
            $scope.headerLabel = 'Company User Settings';
        }

        $scope.isUisEnabled = await FeatureFlagService.isEnabled(Feature.UisEnabled);
        loadParameters();
        loadDropDownValues();
        loadCompanyAdminSettings();
        loadAccountSettings();
        loadChallengeSettings();
    })();
}
