import { FiProductFeatureModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullFiProductFeature: FiProductFeatureModelDto = {
    id: 0,
    updatedDate: new Date().toDateString(),
    name: '',
    status: '',
    type: '',
    isCompanyProductConfigurable: false,
};

export class FiProductFeature extends DomainEntity<FiProductFeatureModelDto> {
    constructor(dto = clone(nullFiProductFeature)) {
        super(dto);
    }

    public createDefault(): this {
        return new FiProductFeature(clone(nullFiProductFeature)) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get status() {
        return this.dto.status;
    }

    public get isCompanyProductConfigurable() {
        return this.dto.isCompanyProductConfigurable;
    }

    public get name() {
        return this.dto.name;
    }
}
