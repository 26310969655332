import { ProductFeatureModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullProductFeature: ProductFeatureModelDto = {
    id: 0,
    updatedDate: new Date().toDateString(),
    name: '',
    status: '',
    type: '',
    permissions: undefined,
    accountIds: [],
    isConfigurable: true,
};

export class ProductFeature extends DomainEntity<ProductFeatureModelDto> {
    constructor(dto = clone(nullProductFeature)) {
        super(dto);
    }

    public createDefault(): this {
        return new ProductFeature(clone(nullProductFeature)) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get permissions() {
        return this.dto.permissions;
    }

    public get status() {
        return this.dto.status;
    }

    public get accountIds() {
        return this.dto.accountIds;
    }

    public get isConfigurable() {
        return this.dto.isConfigurable;
    }

    public get name() {
        return this.dto.name;
    }
}
