import { Injectable } from '@jack-henry/frontend-utils/di';
import { OfxsettingsClient } from '@treasury/api/bo';
import { FiNumberModel } from './entities/fi-number-model.entity';

@Injectable()
export class OfxSettingsService {
    constructor(private readonly ofxSettingsClient: OfxsettingsClient) {}

    /**
     * Retrieves the OFX Enabled setting for an FI ID.
     * @param fiId - The ID of the institution.
     * @returns A Promise that resolves to a Company object.
     */
    public async getOfxEnabledFlagForFi(fiId: string) {
        const ofxEnabledResponse = (
            await this.ofxSettingsClient.ofxSettingsGetOfxEnabledFlagForFi({
                fiId,
            })
        ).data;

        return new FiNumberModel(ofxEnabledResponse);
    }
}
