angular.module('backOffice').directive('darkPositivePayAccountTable', darkPositivePayAccountTable);

function darkPositivePayAccountTable() {
    return {
        controller: darkPositivePayAccountTableController,
        restrict: 'E',
        scope: {
            apiFeatureName: '=',
            posPay: '=',
            allAccounts: '=accounts',
            checkProperty: '=',
            reportTitle: '=',
            featureIsAvailable: '=',
            accountWarnings: '=',
            handleAddAccount: '=',
            customError: '=',
            isLoading: '=',
        },
        template: require('./darkPositivePayAccountTable.html'),
    };
}

darkPositivePayAccountTableController.$inject = ['$scope', '$modal'];

function darkPositivePayAccountTableController($scope, $modal) {
    const allCifs = 'Select All';
    $scope.accountSearch = '';
    $scope.checkAll = false;
    $scope.cifNumbers = [];
    $scope.displayedAccounts = $scope.allAccounts;
    $scope.orderByField = 'accountNumber';
    $scope.reverseSort = false;
    $scope.selectedCifNumber = allCifs;
    $scope.checkAllChanged = checkAllChanged;
    $scope.chevronClass = chevronClass;
    $scope.displayAccountSearchModal = displayAccountSearchModal;
    $scope.hasAccountsForDisplay = hasAccountsForDisplay;
    $scope.hasNoAccounts = hasNoAccounts;
    $scope.isLoading = true;
    $scope.orderByFunction = orderByFunction;
    $scope.showNoDisplayableAccounts = showNoDisplayableAccounts;
    $scope.updateCheckAll = updateCheckAll;
    $scope.updateDisplayedAccounts = updateDisplayedAccounts;
    $scope.updateOrderByField = updateOrderByField;
    $scope.getWarningMessage = getWarningMessage;

    $scope.$watch('allAccounts', accountsUpdated);
    $scope.$watch('featureIsAvailable', featureIsAvailableUpdated);

    function accountsUpdated() {
        $scope.isLoading = !$scope.allAccounts;
        initCifFilter();
        if ($scope.featureIsAvailable) {
            updateDisplayedAccounts();
        } else {
            deselectAllAccounts();
            filterAccounts();
        }
    }

    function checkAllChanged() {
        $scope.checkAll = !$scope.checkAll;
        $scope.displayedAccounts.forEach(account => {
            updateAccountChecked(account, $scope.checkAll);
        });
    }

    function chevronClass(orderBy) {
        const classes = [];

        if ($scope.orderByField !== orderBy) {
            classes.push('invisible');
        }

        if ($scope.reverseSort) {
            classes.push('fa fa-chevron-down');
        } else {
            classes.push('fa fa-chevron-up');
        }

        return classes.join(' ');
    }

    function deselectAllAccounts() {
        $scope.checkAll = false;
        $scope.allAccounts.forEach(account => {
            updateAccountChecked(account, false);
        });
    }

    function displayAccountSearchModal() {
        const modalInstance = $modal.open({
            template: require('../views/positivePayAccountSearchModal.html'),
            size: 'md',
            controller: 'PositivePayAccountSearchModalController',
            backdrop: 'static',
            resolve: {
                apiFeatureName: () => $scope.apiFeatureName,
                displayedAccounts: () => $scope.displayedAccounts,
                posPay: () => $scope.posPay,
            }
        });
        modalInstance.result.then(account => {
            if (!account) return;
            $scope.displayedAccounts.push(account);
            $scope.handleAddAccount(account);
        });
    }

    function featureIsAvailableUpdated() {
        if (!$scope.featureIsAvailable) {
            deselectAllAccounts();
        }
    }

    function filterAccounts() {
        let filteredAccounts = $scope.allAccounts;
        if ($scope.selectedCifNumber !== allCifs) {
            filteredAccounts = filteredAccounts.filter(
                item => item.cifNumber === $scope.selectedCifNumber
            );
        }

        if ($scope.accountSearch.length) {
            const searchText = $scope.accountSearch.toLowerCase();
            filteredAccounts = $scope.allAccounts.filter(
                account =>
                    account.accountType.toLowerCase().indexOf(searchText) > -1 ||
                    account.accountNumber.toLowerCase().indexOf(searchText) > -1 ||
                    account.cifNumber.toLowerCase().indexOf(searchText) > -1
            );
        }

        $scope.displayedAccounts = filteredAccounts;
    }

    function hasNoAccounts() {
        if (!$scope.allAccounts) return true;
        return $scope.allAccounts.length === 0;
    }

    function showNoDisplayableAccounts() {
        if (!$scope.allAccounts || !$scope.displayedAccounts) return false;
        return $scope.allAccounts.length > 0 && $scope.displayedAccounts.length === 0;
    }

    function hasAccountsForDisplay() {
        if (!$scope.displayedAccounts) return false;
        return $scope.displayedAccounts.length !== 0;
    }

    function initCifFilter() {
        $scope.cifNumbers = [];
        const distinctCifs = {};
        angular.forEach($scope.allAccounts, account => {
            if (account.cifNumber) {
                distinctCifs[account.cifNumber] = true;
            }
        });
        $scope.cifNumbers = Object.getOwnPropertyNames(distinctCifs);
        $scope.cifNumbers.unshift(allCifs);
    }

    function orderByFunction(account) {
        if ($scope.orderByField === 'accountNumber') return parseFloat(account.accountNumber);
        return account[$scope.orderByField];
    }

    function updateAccountChecked(account, isChecked) {
        account[$scope.checkProperty] = isChecked;
        $scope.posPay.updateAccount($scope.check, account);
    }

    function updateDisplayedAccounts() {
        filterAccounts();
        updateCheckAll();
    }

    function updateCheckAll() {
        if (!$scope.displayedAccounts) return false;
        const anyAccountsUnchecked = $scope.displayedAccounts.some(
            account => !account[$scope.checkProperty]
        );
        if (anyAccountsUnchecked || $scope.displayedAccounts.length === 0) {
            $scope.checkAll = false;
        } else {
            $scope.checkAll = true;
        }
    }

    function updateOrderByField(newOrderBy) {
        $scope.orderByField = newOrderBy;
        $scope.reverseSort = !$scope.reverseSort;
    }

    function getWarningMessage(account) {
        return $scope.accountWarnings[account.accountId];
    }
}
