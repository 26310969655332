import { InjectProperty } from '@jack-henry/frontend-utils/di';
import { Record as FdlRecord } from '@treasury/FDL';
import { FdlFieldDefinitions } from '@treasury/FDL/record';
import { NavigationService } from '@treasury/core/navigation';
import { BankDto, TmApiError, WireDto } from '@treasury/domain/shared/types/';
import { BeneficiaryDto } from '@treasury/domain/shared/types/beneficiary.dto';
import { ListeningElementMixin } from '@treasury/omega/components';
import '@treasury/omega/components/omega-button';
import '@treasury/omega/components/omega-field';
import { fontAwesome } from '@treasury/omega/css/icons/font-awesome.js';
import { AlertMixin } from '@treasury/omega/mixins';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../components/blocking-loader';
import { WirePaymentDetailClient } from '../clients/wire-payment-detail-client';
import { createBankFieldDefinitions } from '../data/bank-field-definitions';
import { BeneficiaryFieldDefinitions } from '../data/beneficiary-field-definitions';
import { WireFieldDefinitions } from '../data/wire-field-definitions';
import { CardStates } from './enums/card-states';
import { renderBeneficiary } from './wire-card-templates/render-beneficiary.template';
import { renderExchangeRate } from './wire-card-templates/render-exchange-rate.template';
import { renderReadOnlyAddress } from './wire-card-templates/render-read-only-address.template';

const mixin = AlertMixin(ListeningElementMixin(LitElement));

interface RouteParams {
    reportClient: object;
}

@customElement('wire-payment-detail-container')
export class WirePaymentDetailContainer extends mixin {
    @InjectProperty()
    private declare readonly client: WirePaymentDetailClient;

    @InjectProperty()
    private declare readonly navService: NavigationService;

    @property({ type: String })
    public cardState = CardStates.ReadOnly;

    @property({ type: Boolean })
    public canUserEditWire = false;

    @property({ type: Boolean })
    public isFormInEditMode = false;

    @property({ type: Object })
    public downloadFilter!: object;

    @property({ type: String })
    public downloadPage!: string;

    @property({ type: Boolean })
    public hasConfirmationButton = false;

    @property({ type: Boolean })
    public hasReviewButton = false;

    @property({ type: Boolean })
    public isReviewButtonDisabled = false;

    @property({ type: Boolean })
    public isSaving = false;

    @property({ type: Object })
    public returnReport!: object;

    @property({ type: Number })
    public returnReportId!: number;

    @property({ type: String })
    public returnReportName!: string;

    @property({ type: Object })
    public statusList!: object;

    @state()
    loading = true;

    @state()
    @property({ type: Object })
    public wire!: WireDto;

    @state()
    @property({ type: Object })
    private wireRecord!: FdlRecord<WireDto>;

    public wireDtoDefinitions: FdlFieldDefinitions<WireDto> = WireFieldDefinitions;

    @state()
    @property({ type: Object })
    private beneficiaryRecord!: FdlRecord<BeneficiaryDto>;

    public beneficiaryDtoDefinitions: FdlFieldDefinitions<BeneficiaryDto> =
        BeneficiaryFieldDefinitions;

    @state()
    @property({ type: Object })
    private bankRecord!: FdlRecord<BankDto>;

    reportClient: any;

    private wireId?: number;

    async firstUpdated() {
        this.loading = true;
        const { params } = await this.navService.getRouteData<RouteParams>();

        this.reportClient = params.reportClient;
        const urlPathParams = window.location.pathname.split('/');
        this.wireId = Number(urlPathParams.pop() as string);

        try {
            if (!this.wireId || isNaN(this.wireId)) {
                throw 'Invalid Wire Payment ID';
            }
            this.wire = (await this.client.getWireDetails(
                Number(this.wireId)
            )) as unknown as WireDto;
            this.wireRecord = new FdlRecord(this.wireDtoDefinitions, this.wire);
            // Bank label should only be 'Routing Number' when it is a domestic Wire Payment Report
            let bankLabel;
            if (!this.wireRecord.getField('isDli') && !this.wire.isInternational) {
                bankLabel = 'Routing Number';
            } else if (this.wireRecord.getField('destinationCurrency') === 'USD') {
                bankLabel = 'Bank Id';
            } else {
                bankLabel = 'Beneficiary Bank Id';
            }
            if (this.wireRecord) {
                this.beneficiaryRecord = new FdlRecord<BeneficiaryDto>(
                    this.beneficiaryDtoDefinitions,
                    this.wireRecord.getField('beneficiary')
                );
                if (this.beneficiaryRecord) {
                    this.bankRecord = new FdlRecord<BankDto>(
                        createBankFieldDefinitions(bankLabel),
                        this.beneficiaryRecord.getField('bank')
                    );
                }
                this.wireRecord.setField('isWireEditable', false);
                this.cardState = await this.initializeCardState();
                this.createWireRecordListener();
            }
        } catch (err) {
            if (err instanceof TmApiError) {
                const { message, errorCode: code, timestamp: time } = err;
                this.alert = {
                    ...this.alert,
                    visible: true,
                    type: 'error',
                    message,
                    code,
                    time,
                };
            } else {
                const message =
                    typeof err === 'string' ? err : 'An unknown error occurred. Please try again.';
                this.alert = { ...this.alert, visible: true, type: 'error', message };
            }
        } finally {
            this.loading = false;
        }
    }

    createWireRecordListener() {
        this.listenTo(this.wireRecord, 'change', ({ detail }: any) => {
            const { field } = detail;
            if (field === 'status') {
                const statusValue = this.wireRecord.getField('status');
                if (statusValue.toLowerCase() === 'transmitted')
                    this.wireRecord.values.statusId = 4;
                if (statusValue.toLowerCase() === 'posted') this.wireRecord.values.statusId = 6;
            }
            this.requestUpdate();
        });
    }

    async initializeCardState() {
        const hasFXEditEntitlement = await this.client.hasEntitlement('Edit FX Wire Payment');
        if (
            this.wire.status === 'Transmitted' &&
            this.wire.externallyProcessed &&
            hasFXEditEntitlement
        ) {
            return CardStates.CanEdit;
        }
        return CardStates.ReadOnly;
    }

    async downloadWireDetail(e: any) {
        await this.reportClient.downloadWireDetails(this.wire.id, e.detail.downloadType);
    }

    async updateWire() {
        this.isSaving = true;
        try {
            await this.client.UpdateExternallyProcessedFXWire(this.wireRecord.values);
            this.cardState = CardStates.IsSaved;
            this.wireRecord.setField('isWireEditable', false);
            this.alert = {
                ...this.alert,
                type: 'success',
                message: 'Wire Successfully Updated',
                visible: true,
            };
            this.dispatchEvent(new CustomEvent('wire-updated', { detail: this.wireRecord.values }));
        } catch (e: any) {
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message: e.message,
            };
        } finally {
            this.isSaving = false;
        }
    }

    clickedEdit() {
        this.wireRecord.setField('isWireEditable', true);
        this.cardState = CardStates.IsEditingCanSubmit;
    }

    clickedReview() {
        this.wireRecord.setField('isWireEditable', false);
        this.cardState = CardStates.WasEditedIsUnsaved;
    }

    renderWireDetail() {
        if (!this.wireRecord) return nothing;
        return html`<div class="details-body omega-flex-form">
            <div class="form-column">
                <h1>Payment Information</h1>
                <omega-field field="transactionId" .record=${this.wireRecord}></omega-field>
                <omega-field
                    field="confirmationNumber"
                    .record=${this.wireRecord}
                    label="OMAD"
                ></omega-field>
                <omega-field field="status" .record=${this.wireRecord}></omega-field>
                <omega-field field="wireCompany" .record=${this.wireRecord}></omega-field>
                <omega-field field="debitAccount" .record=${this.wireRecord}></omega-field>
                <omega-field field="amount" .record=${this.wireRecord}></omega-field>
                <omega-field field="frequency" .record=${this.wireRecord}></omega-field>
                <omega-field field="destinationCurrency" .record=${this.wireRecord}></omega-field>
                <omega-field field="purpose" .record=${this.wireRecord}></omega-field>
                <omega-field field="additionalInformation" .record=${this.wireRecord}></omega-field>
                <omega-field field="referenceBeneficiary" .record=${this.wireRecord}></omega-field>
            </div>
            <div class="form-column">
                ${renderBeneficiary(this.beneficiaryRecord, this.wireRecord, this.bankRecord)}
            </div>
            <div class="form-column">
                <omega-field field="audit" .record=${this.wireRecord}></omega-field>
            </div>
        </div>`;
    }

    renderIntermediaryBank(bank: FdlRecord<BankDto>) {
        const bankAddress = {
            addressLine1: bank.getField('addressLine1'),
            addressLine2: bank.getField('addressLine2'),
            addressLine3: bank.getField('addressLine3'),
            city: bank.getField('city'),
            state: bank.getField('state'),
            postalCode: bank.getField('postalCode'),
            country: bank.getField('country'),
        };
        return html` <div class="details-body omega-flex-form">
            <div class="form-column">
                <omega-field field="bankId" .record=${bank}></omega-field>
                <omega-field field="name" .record=${bank}></omega-field>
                <div class="addressRow">
                    <div class="addressLabel">Bank Address</div>
                    <div class="addressValue">${renderReadOnlyAddress(bankAddress)}</div>
                </div>
                <omega-field field="countryCode" .record=${bank}></omega-field>
                <omega-field field="notes" .record=${bank}></omega-field>
            </div>
        </div>`;
    }

    renderIntermediaryBankList() {
        if (!this.beneficiaryRecord) return nothing;
        const bankArray: Array<BankDto> = this.beneficiaryRecord.getField('intermediaryBanks');
        if (!bankArray) return html``;
        return html`<style>
                .intermediary-bank h1 {
                    margin: 10px 0 0 20px;
                    font-weight: 600;
                }
                .omega-flex-form omega-field {
                    margin: 5px 10px;
                }
            </style>
            <div class="intermediary-bank">
                <h1>Intermediary Bank Information</h1>
                ${bankArray.map(bank =>
                    this.renderIntermediaryBank(
                        new FdlRecord<BankDto>(createBankFieldDefinitions('Bank Id'), bank)
                    )
                )}
            </div>`;
    }

    renderDownloadBar() {
        if (!this.wireRecord) return nothing;
        return html`<div class="jh-header background-white">
            <omega-download-bar
                pageTitle="Wire Detail - ${this.wireRecord.values.transactionId}"
                .actions=${['download']}
                .hideActionLabels=${true}
                .downloadOptions=${['CSV', 'PDF']}
                @download=${(e: any) => this.downloadWireDetail(e)}
            ></omega-download-bar>
        </div>`;
    }

    renderBackButton() {
        const backButtonTitle = this.wireRecord
            ? this.wireRecord.getField('isDli')
                ? 'Back to FX Wire Payment Report'
                : 'Back to Wire Payment Report'
            : 'Back to Report';
        return html` <omega-button
            id="go-to-user-list"
            type="icon"
            class="fa fa-chevron-left"
            @click="${() => {
                window.history.back();
            }}"
            >${backButtonTitle}</omega-button
        >`;
    }

    renderEditButtons() {
        switch (this.cardState) {
            case CardStates.CanEdit: {
                return html`<omega-button type="primary" @click="${() => this.clickedEdit()}"
                    >Edit</omega-button
                >`;
            }
            case CardStates.IsEditingCanSubmit: {
                return html`<omega-button
                        @click=${() => {
                            this.wireRecord.reset();
                            this.cardState = CardStates.CanEdit;
                        }}
                        >Cancel</omega-button
                    ><omega-button
                        type="primary"
                        .disabled=${this.wireRecord.errorCount() > 0}
                        @click="${() => this.clickedReview()}"
                        >Review</omega-button
                    >`;
            }
            case CardStates.WasEditedIsUnsaved: {
                return html`<omega-button type="primary" @click="${() => this.clickedEdit()}"
                        >Edit</omega-button
                    >
                    <omega-button type="primary" @click="${() => this.updateWire()}"
                        >Confirm</omega-button
                    >`;
            }
            case CardStates.IsSaved: {
                return nothing;
            }
            default: {
                return nothing;
            }
        }
    }

    renderBlockingLoader() {
        if (this.isSaving || this.loading) return html`<blocking-loader></blocking-loader>`;
        return nothing;
    }

    render() {
        return html` ${this.renderAlert()}${this.renderBlockingLoader()}
            <div class="details-container">
                ${this.renderBackButton()} ${this.renderDownloadBar()}
                <div class="form-container wire-detail-card">
                    ${this.renderWireDetail()} ${this.renderIntermediaryBankList()}
                    ${renderExchangeRate(this.wireRecord)} ${this.renderEditButtons()}
                </div>
            </div>`;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [
            fontAwesome,
            css`
                h1 {
                    margin: 0 0 12px 10px;
                    font-weight: 600;
                }
                .section-title {
                    margin: 0;
                }
                .jh-header {
                    color: #505050;
                    border-bottom: 1px solid #dddddd;
                    height: 50px;
                    margin-left: 15px;
                    margin-right: 15px;
                    position: relative;
                }
                .jh-header h1 {
                    font-size: 27px;
                }
                .details-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0 10px;
                }
                .details-footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0 10px;
                }
                .omega-flex-form {
                    display: flex;
                }
                .background-white {
                    background: white;
                }
                .wire-detail-card {
                    margin-left: 15px;
                    margin-right: 15px;
                    background: white;
                }
                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }
                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 5px 10px;
                }
            `,
        ];
    }
}
