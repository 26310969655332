import { Injectable } from '@jack-henry/frontend-utils/di';
import { TmHttpClient } from '@treasury/core/http';

@Injectable()
export class WirePaymentDetailRequests {
    // eslint-disable-next-line no-useless-constructor
    constructor(private readonly http: TmHttpClient) {}

    async fetchWireDetails(id: number) {
        return this.http.request(`wires/${id}`, {
            method: 'GET',
        });
    }

    async updateExternallyProcessedFXWire(body: object) {
        return this.http.request(`wires/updateexternallyprocessedfxwire`, {
            method: 'POST',
            body,
        });
    }
}
