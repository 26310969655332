import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    ChallengeGroupsClient,
    CompaniesClient,
    CompanyAccountSettingsModelDto,
    ProductFeatureModelDto,
} from '@treasury/api/bo';
import { ChallengeGroup, Company, CompanyUser } from './';
import { CompanyAccount } from './entities/company-account.entity';
import { FiProductFeature } from './entities/fi-product-feature.entity';
import { ProductFeature } from './entities/product-feature.entity';

@Injectable()
export class CompanyService {
    constructor(
        private readonly companiesClient: CompaniesClient,
        private readonly challengeGroupsClient: ChallengeGroupsClient
    ) {}

    /**
     * Retrieves a company by its ID and type.
     * @param companyId - The ID of the company.
     * @param companyType - The type of the company.
     * @returns A Promise that resolves to a Company object.
     */
    public async getCompanyById(companyId: number, companyType: number) {
        const companyResponse = await this.companiesClient.companiesGetOnboardedCompany(
            companyId,
            companyType
        );

        const challengeGroups = await this.getAllChallengeGroups();

        return new Company(companyResponse.data, challengeGroups);
    }

    /**
     * Retrieves all challenge groups.
     * @returns {Promise<ChallengeGroup[]>} A promise that resolves to an array of ChallengeGroup objects.
     */
    public async getAllChallengeGroups() {
        const challengeGroups = await this.challengeGroupsClient.challengeGroupsGetAll();
        return challengeGroups.data.map(cg => new ChallengeGroup(cg));
    }

    async getCompanyAccounts(companyId: number) {
        const accounts = (await this.companiesClient.companiesAccountsAllGet(companyId)).data;
        return accounts.map(account => new CompanyAccount(account));
    }

    async getUsers(companyId: number) {
        const users = (await this.companiesClient.companiesUsersAll(companyId, false)).data;
        return users.map(user => new CompanyUser(user));
    }

    async updateCompanyStatus(companyId: number, accountId: number, active: boolean) {
        return this.companiesClient.companiesAccountStatus(
            companyId,
            accountId,
            active ? 'Active' : 'Inactive'
        );
    }

    async getCompanyAvailableProductFeatures(companyId: number) {
        const features = (
            await this.companiesClient.companiesGetAvailableProductsForCompany(companyId)
        ).data;
        return features.map(feature => new FiProductFeature(feature));
    }

    async getCompanyProductFeatures(companyId: number) {
        const features = (await this.companiesClient.companiesProductFeaturesAll(companyId)).data;
        return features.map(feature => new ProductFeature(feature));
    }

    async updateProductFeature(
        companyId: number,
        featureId: number,
        productFeature: ProductFeatureModelDto
    ) {
        return await this.companiesClient.companiesProductFeaturesPut(
            companyId,
            featureId,
            productFeature
        );
    }

    async getCompanyUserAccountSettings(
        companyId: number
    ): Promise<CompanyAccountSettingsModelDto> {
        return (await this.companiesClient.companiesUserAccountSettingsGet(companyId)).data;
    }

    async saveCompanyUserAccountSettings(
        companyId: number,
        dto: CompanyAccountSettingsModelDto
    ): Promise<CompanyAccountSettingsModelDto> {
        return (await this.companiesClient.companiesUserAccountSettingsPut(companyId, dto)).data;
    }
}
