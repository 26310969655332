/* eslint-disable no-use-before-define */
/* eslint-disable global-require */
import { EntitlementsService } from '@treasury/domain/backoffice/services';
import { Ng1StateDeclaration, StateParams, StateService } from '@uirouter/angularjs';

export const states: Record<string, Ng1StateDeclaration> = {
    'feature-flags': {
        url: `/feature-flags`,
        templateProvider: async () => {
            await import('../src/dark/containers/feature-flags.js');
            return `<feature-flags></feature-flags>`;
        },
    },
    branding: {
        url: `/dark/branding`,
        templateProvider: async () => {
            await import('../src/branding/branding-container');
            return `<branding-container></branding-container>`;
        },
    },
    dark: {
        url: `/dark`,
        templateProvider: async () => {
            await import('../src/dark/containers/dark-container.js');
            return `<dark-container></dark-container>`;
        },
    },
    login: {
        url: `/login`,
        template: require('./login/views/login.html'),
    },
    xperienceLogin: {
        url: `/xperience/login`,
        templateProvider: async () => {
            await import('./login/components/xperience-login-container');
            return `<xperience-login></xperience-login>`;
        },
    },
    version: {
        template: `<div id='versionTreasury'>${
            (window as any).treasury ? (window as any)?.version : ''
        }</div>`,
        url: `/version`,
    },
    'user-verification': {
        url: `/user-verification`,
        template: require('./login/views/userVerification.html'),
    },
    'user-verification-reset': {
        url: `/user-verification-reset`,
        template: require('./login/views/userVerificationReset.html'),
    },
    'change-password': {
        url: `/change-password`,
        template: require('./login/views/changePassword.html'),
        params: {
            nextStep: null,
            reason: null,
        },
    },
    'create-security-questions': {
        url: `/create-security-questions`,
        template: require('./login/views/createSecurityQuestions.html'),
    },
    'answer-security-questions': {
        url: `/answer-security-questions`,
        template: require('./login/views/answerSecurityQuestions.html'),
        params: {
            nextStep: null,
            reason: null,
        },
    },
    'authenticate-secure-token': {
        url: `/authenticate-secure-token`,
        template: require('./login/views/authenticateSecureToken.html'),
        params: {
            nextStep: null,
            reason: null,
        },
        controllerAs: 'vm',
        controller: 'AuthenticateSecureTokenController',
    },
    'register-secure-token': {
        url: `/register-secure-token`,
        template: require('./login/views/registerSecureToken.html'),
        params: {
            nextStep: null,
            reason: null,
        },
        controllerAs: 'vm',
        controller: 'RegisterSecureTokenController',
    },
    'forgot-password': {
        url: `/forgot-password`,
        template: require('./login/views/forgotPassword.html'),
    },
    'email-sent-confirmation': {
        url: `/email-sent-confirmation`,
        template: require('./login/views/emailSentConfirmation.html'),
    },
    'locked-out': {
        url: `/locked-out`,
        template: require('./login/views/lockedOut.html'),
    },
    'unlock-user': {
        url: `/unlock-user`,
        template: require('./login/views/unlockUser.html'),
    },
    company: {
        url: `/company/{id}`,
        template: require('./company/profile/detail/views/detailContainer.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-beta': {
        url: `/company/{id}/beta`,
        template: require('./company/profile/detail/views/detailContainer.html'),
        params: { id: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-wire-transfer': {
        url: `/company/{id}/wire-transfer/{productFeatureId}?type`,
        template: require('./company/profile/productFeatures/views/customizeWireTransfer.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-account-recon': {
        url: `/company/{companyId}/account-recon-config`,
        params: { companyId: null, action: 'edit' },
        controller: [
            '$scope',
            '$stateParams',
            function accountReconController(
                $scope: ng.IRootScopeService,
                $stateParams: StateParams
            ) {
                ($scope as any).companyAccountRecon = $stateParams;
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
        templateProvider: async () => {
            await import(
                '../src/company/product-features/company-account-reconciliation-reporting-configuration.js'
            );
            return `<company-account-reconciliation-reporting-configuration
                    companyId="{{companyAccountRecon.companyId}}"
                    action="{{companyAccountRecon.action}}">
                    </company-account-reconciliation-reporting-configuration>`;
        },
    },
    'company-ach': {
        url: `/company/{id}/ach/{productFeatureId}?type`,
        template: require('./company/profile/productFeatures/views/customizeAch.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-arp': {
        url: `/company/{id}/arp/{productFeatureId}?type`,
        templateProvider: async () => {
            if (await EntitlementsService.hasEntitlement('Feature.PositivePay.NewBoConfigs')) {
                return require(`./company/profile/productFeatures/views/darkCustomizeArp.html`);
            }
            return require('./company/profile/productFeatures/views/customizeArp.html');
        },
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-stop-payment': {
        url: `/company/{id}/stop-payment/{productFeatureId}?type`,
        template: require('./company/profile/productFeatures/views/customizeStopPaymentView.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-bill-pay': {
        url: `/company/{id}/bill-pay/{productFeatureId},`,
        template: require('./company/profile/productFeatures/views/customizeBillPayView.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-electronic-documents': {
        url: `/company/{id}/electronic-documents/{productFeatureId}?type`,
        template: require('./company/profile/productFeatures/views/customizeElectronicDocumentsView.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-remote-deposit-capture': {
        url: `/company/{id}/remote-deposit-capture/{productFeatureId},`,
        template: require('./company/profile/productFeatures/views/customizeRemoteDepositCaptureView.html'),
        controller: 'CustomizeRemoteDepositCaptureController',
        controllerAs: 'vm',
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    'company-search': {
        url: `/company-search?searchType=company`,
        template: require('./company/search/views/companySearchView.html'),
        data: {
            entitlements: ['Execute Company Search', 'Switch FI'],
        },
    },
    'company-search-dark': {
        url: `/company-search-dark`,
        templateProvider: async () => {
            await import('../src/company/company-list/containers/company-listing');
            return `<company-listing></company-listing>`;
        },
        data: {
            entitlements: ['Execute Company Search', 'Switch FI'],
        },
    },
    'institution-search': {
        url: `/institution-search`,
        template: require('./jhaSupport/views/institutionSearchView.html'),
        controller: 'InstitutionSearchController',
        controllerAs: 'vm',
        data: {
            entitlements: ['Switch FI'],
        },
    },
    configuration: {
        url: `/configuration`,
        template: require('./configuration/productFeature/views/productFeatureConfiguration.html'),
        data: {
            entitlements: [
                'View Product Feature Configuration',
                'Edit Product Feature Configuration',
                'Switch FI',
            ],
        },
    },
    'account-reconciliation-reports-configuration': {
        url: `/account-reconciliation-reports-configuration`,
        templateProvider: async () => {
            await import(
                '../src/configuration/product-feature/account-reconciliation-reports-configuration.js'
            );
            return `<account-reconciliation-reports-configuration></account-reconciliation-reports-configuration>`;
        },
    },
    'reporting-configuration': {
        url: `/reporting-configuration/{productFeatureId},`,
        template: require('./configuration/productFeature/views/configureInformationReporting.html'),
        params: {
            productFeatureId: null,
            productFeatureStatus: null,
            canEdit: null,
        },
        data: {
            entitlements: [
                'Edit Product Feature Configuration',
                'View Product Feature Configuration',
                'Switch FI',
            ],
        },
    },
    'positivepay-configuration-fi': {
        url: `/positive-pay-configuration?productFeatureId&productFeatureStatus&canEdit`,
        templateProvider: async () => {
            if (await EntitlementsService.hasEntitlement('Feature.PositivePay.NewBoConfigs')) {
                await import('../src/configuration/product-feature/positive-pay-config.js');
                return `<positive-pay-config></positive-pay-config>`;
            }
            return require('./configuration/productFeature/views/configurePositivePay.html');
        },
        params: {
            productFeatureId: null,
            productFeatureStatus: null,
            canEdit: null,
        },
        data: {
            entitlements: [
                'Edit Product Feature Configuration',
                'View Product Feature Configuration',
                'Switch FI',
            ],
        },
    },
    'ach-configuration': {
        url: `/ach-configuration/{productFeatureId},`,
        template: require('./configuration/productFeature/views/configureAch.html'),
        controller: 'ConfigureAchController',
        params: {
            productFeatureId: null,
            productFeatureStatus: null,
            canEdit: null,
        },
        data: {
            entitlements: [
                'Edit Product Feature Configuration',
                'View Product Feature Configuration',
                'Switch FI',
            ],
        },
    },
    'fi-user-list': {
        url: `/fi-user-list`,
        template: require('./configuration/fiUserList/views/fiUserList.html'),
        data: {
            entitlements: ['View FI User', 'Add FI User', 'Edit FI User', 'Switch FI'],
        },
    },
    'fi-role-list': {
        url: `/fi-role-list`,
        template: require('./configuration/fiRolesList/views/fiRolesList.html'),
        data: {
            entitlements: ['Add User Role', 'Edit User Role', 'View User Role', 'Switch FI'],
        },
    },
    cpp: {
        url: `/cpp`,
        template: require('./configuration/passwordParameters/views/passwordParameters.html'),
        data: {
            entitlements: [
                'Manage Company User Settings',
                'View Company User Settings',
                'Switch FI',
            ],
        },
    },
    fipp: {
        url: `/fipp`,
        template: require('./configuration/passwordParameters/views/passwordParameters.html'),
        data: {
            entitlements: ['Manage FI User Settings', 'View FI User Settings', 'Switch FI'],
        },
    },
    'dashboard-configuration': {
        url: `/dashboard-configuration`,
        template: require('./configuration/dashboard/views/dashboardConfiguration.html'),
        data: {
            entitlements: [
                'Resources Panel Configuration Add Link',
                'Edit Resources Panel Configuration',
                'View Resource Panel Configuration',
                'Information Center Configuration Add Message',
                'Edit Information Center Configuration',
                'View Information Center Configuration',
                'Switch FI',
            ],
        },
    },
    'processing-configuration': {
        url: `/processing-configuration`,
        template: require('./configuration/processing/views/processingConfiguration.html'),
        data: {
            entitlements: [
                'Processing Configuration Edit Cutoff',
                'Processing Configuration Holiday Exceptions Add',
                'Processing Configuration Holiday Exceptions Edit',
                'Processing Configuration Holiday Exceptions Delete',
                'View Processing Configuration',
                'Switch FI',
            ],
        },
    },
    'messaging-center-configuration': {
        url: `/messaging-center`,
        template: require('./configuration/messagingCenter/views/messagingCenterConfiguration.html'),
        data: {
            entitlements: [
                'View Message Center Configuration',
                'Manage Message Center Configuration',
                'Switch FI',
            ],
        },
    },
    'content-management-configuration': {
        url: `/content-management`,
        template: require('./configuration/contentManagement/views/contentManagementConfiguration.html'),
        data: {
            entitlements: [
                'Edit Content Management',
                'View Content Management Configuration',
                'Switch FI',
            ],
        },
    },
    'terms-conditions-configuration': {
        url: `/terms-conditions-configuration`,
        template: require('./configuration/termsAndConditions/views/termsAndConditionsConfiguration.html'),
        data: {
            entitlements: ['View Terms and Conditions', 'Manage Terms and Conditions', 'Switch FI'],
        },
    },
    'tc-configuration': {
        url: `/tc-configuration`,
        template: require('./configuration/transactionCode/views/transactionCodeConfiguration.html'),
        data: {
            entitlements: [
                'Manage Transaction Code Configuration',
                'View Transaction Code Configuration',
                'Switch FI',
            ],
        },
    },
    'notification-configure': {
        url: '/notification-configuration',
        template: require('./configuration/notifications/configureNotifications.html'),
        data: {
            entitlements: [
                'Edit Notification Configuration',
                'View Notification Configuration',
                'Switch FI',
            ],
        },
    },
    'user-information-view': {
        url: `/{companyId}/users/{id}/user-information/view?view`,
        template: require('./company/profile/userInfo/views/userInformationView.html'),
        params: {
            companyId: null,
            id: null,
        },
        data: {
            entitlements: ['Edit User', 'Add User', 'Access Company Profile', 'Switch FI'],
        },
    },
    'user-information-save': {
        url: `/{companyId}/users/{id}/user-information/save?view`,
        template: require('./company/profile/userInfo/views/userInformationView.html'),
        params: {
            companyId: null,
            id: null,
        },
        data: {
            entitlements: ['Edit User', 'Add User', 'Access Company Profile', 'Switch FI'],
        },
    },
    'user-information-edit-user': {
        url: `/{companyId}/users/{id}/user-information/edit?view`,
        template: require('./company/profile/userInfo/views/userInformationView.html'),
        params: {
            company: null,
            companyId: null,
            id: null,
        },
        data: {
            entitlements: ['Edit User', 'Add User', 'Access Company Profile', 'Switch FI'],
        },
    },
    'user-information-create-user': {
        url: `/{companyId}/users/user-information/create?view`,
        template: require('./company/profile/userInfo/views/userInformationView.html'),
        params: {
            company: null,
            companyId: null,
        },
        data: {
            entitlements: ['Edit User', 'Add User', 'Access Company Profile', 'Switch FI'],
        },
    },
    'user-information-copy-user': {
        url: `/{companyId}/users/user-information?view`,
        template: require('./company/profile/userInfo/views/userInformationView.html'),
        params: {
            company: null,
            companyId: null,
            copyUser: null,
        },
        data: {
            entitlements: ['Edit User', 'Add User', 'Access Company Profile', 'Switch FI'],
        },
    },

    reports: {
        abstract: true,
        template: '<ui-view />',
    },
    'reports.dashboard': {
        url: `/reports/dashboard`,
        template: require('./reports/views/reportsDashboardView.html'),
        data: {
            entitlements: ['Manage Reports', 'Switch FI'],
        },
    },
    'reports.beta-dashboard': {
        url: `/reports/beta-dashboard`,
        templateProvider: async () => {
            await import('../src/reports/containers/report-dashboard-container.js');
            return `<report-dashboard-container></report-dashboard-container>`;
        },
        data: {
            entitlements: ['Manage Reports', 'Switch FI'],
        },
    },
    'reports.wire-upload': {
        url: '/reports/wire-upload',
        templateProvider: async () => {
            await import('../src/reports/wire-upload/wire-upload-container.js');
            return `<wire-upload-container></wire-upload-container>`;
        },
    },
    'reports.wire-payment-detail': {
        url: '/reports/wirePaymentDetails/{id}',
        params: {
            reportClient: null,
        },
        templateProvider: async () => {
            await import('../src/reports/parts/wire-payment-detail-container');
            return `<wire-payment-detail-container></wire-payment-detail-container>`;
        },
    },
    'reports.ach-payment-detail': {
        url: '/reports/achPaymentDetail/{paymentTypeId}/{guid}',
        params: {
            reportClient: null,
        },
        templateProvider: async () => {
            await import('../src/reports/parts/ach-payment-detail-container');
            return `<ach-payment-detail-container></ach-payment-detail-container>`;
        },
    },
    'reports.ach-filter-exception': {
        url: '/reports/ach-filter-exception',
        templateProvider: async () => {
            await import('../src/reports/ach-filter-exception/ach-filter-exception-report.js');
            return `<ach-filter-exception-report></ach-filter-exception-report>`;
        },
    },
    'reports.wire-details': {
        url: `/reports/wire/{id}?reportId`,
        template: require('./reports/views/wireDetail.html'),
        controller: 'WireDetailsController',
        controllerAs: 'vm',
    },
    'reports.view': {
        url: `/reports/{type}/{id}`,
        templateProvider: async () => {
            await import('../src/reports/containers/report-container.js');
            return `<report-container></report-container>`;
        },
        params: {
            report: null,
            stateKey: null,
        },
    },
    'reports.views': {
        url: `/reports/{type}/{id}`,
        template: require('./reports/views/reportsView.html'),
        controller: 'ReportsController',
        controllerAs: 'vm',
        params: {
            report: null,
        },
    },
    'reports.fi-user-activity': {
        url: '/reports/{type}/{id}',
        templateProvider: async () => {
            await import('../src/reports/containers/report-user-activity-container.js');
            return `<report-user-activity-container></report-user-activity-container>`;
        },
        params: {
            report: null,
        },
    },
    'reports.customer-user-activity': {
        url: '/reports/{type}/{id}',
        templateProvider: async () => {
            await import('../src/reports/containers/report-user-activity-container.js');
            return `<report-user-activity-container></report-user-activity-container>`;
        },
        params: {
            report: null,
        },
    },
    'authentication-profiles': {
        url: `/authentication-profiles`,
        template: require('./configuration/challengeGroups/views/challengeGroupList.html'),
        controller: 'ChallengeGroupListController',
        data: {
            entitlements: [
                'Add Challenge Point Profile',
                'Edit Challenge Point Profile',
                'View Challenge Point Profile',
                'Switch FI',
            ],
        },
    },
    'challenge-group-detail': {
        url: `/groups/{id}?type`,
        template: require('./configuration/challengeGroups/views/challengeGroupDetail.html'),
        controller: 'ChallengeGroupDetailController',
        data: {
            entitlements: [
                'Add Challenge Point Profile',
                'Edit Challenge Point Profile',
                'View Challenge Point Profile',
                'Switch FI',
            ],
        },
    },
    'message-center': {
        url: `/message-center`,
        template: require('./messageCenter/views/messageCenterView.html'),
    },
    'account-analysis-configuration-institution': {
        url: `/aa-institution-configuration`,
        template: require('./configuration/accountAnalysis/views/aaInstitutionConfigurationView.html'),
        data: {
            entitlements: [
                'Edit Product Feature Configuration',
                'View Product Feature Configuration',
                'Switch FI',
            ],
        },
    },
    'account-analysis-configuration-company': {
        url: `/company/:companyId/aa-configuration`,
        template: require('./configuration/accountAnalysis/views/aaCompanyConfigurationView.html'),
        params: {
            companyId: null,
            companyName: null,
        },
        data: {
            entitlements: ['Access Company Profile', 'Manage Billing Options', 'Switch FI'],
        },
    },
    'company-wausau-estatements': {
        url: `/company/{id}/wausau-estatements/{productFeatureId}?type`,
        template: require('./company/profile/productFeatures/views/customizeWausauStatementsView.html'),
        params: { cifNumber: null },
        onEnter: [
            '$state',
            '$stateParams',
            function ($state: StateService, $stateParams: StateParams) {
                getIdOrCif($state, $stateParams);
            },
        ],
        data: {
            entitlements: ['Access Company Profile', 'Switch FI'],
        },
    },
    blank: {
        url: `/blank`,
        template: require('./blankview.html'),
    },
};

let globalCifNumber: number | null = null;

function getIdOrCif(stateService: StateService, stateParams: StateParams) {
    if (stateParams.id && stateParams.id > 0) {
        globalCifNumber = null;
    } else if (stateParams.cifNumber) {
        globalCifNumber = stateParams.cifNumber;
    } else if (globalCifNumber) {
        stateParams.cifNumber = globalCifNumber; // eslint-disable-line no-param-reassign
    } else {
        stateService.go('company-search');
    }
}
