import { Injectable } from '@jack-henry/frontend-utils/di';
import { CompanyuserClient as CompanyUserClient } from '@treasury/api/bo';
import { CompanyUser } from './entities';

@Injectable()
export class CompanyUserService {
    constructor(private readonly companyUserClient: CompanyUserClient) {}

    sendEnrollmentEmail(companyId: number, userId: number) {
        this.companyUserClient.companyUserGenerateEnrollmentEmail(companyId, userId);
    }

    async setActive(
        companyId: number,
        userId: number,
        isActive: boolean,
        comments: string | null = null
    ) {
        const user = (
            await this.companyUserClient.companyUserSetActive(companyId, userId, {
                isActive,
                comments,
            })
        ).data;
        return new CompanyUser(user);
    }
}
