import { FiNumberModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullFiNumberModel: FiNumberModelDto = {
    isOfxEnabled: false,
    ofxFiNumber: undefined,
    success: true,
    userLevelAccessEnabled: false,
    ofxReady: false,
};

export class FiNumberModel extends DomainEntity<FiNumberModelDto> {
    constructor(dto = clone(nullFiNumberModel)) {
        super(dto);
    }

    public createDefault(): this {
        return new FiNumberModel(clone(nullFiNumberModel)) as this;
    }

    public get isOfxEnabled() {
        return this.dto.isOfxEnabled;
    }

    public get ofxReady() {
        return this.dto.ofxReady;
    }
}
